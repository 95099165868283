import { SecureStoragePlugin } from "capacitor-secure-storage-plugin"

import { AccountTypes } from "@components/appProviders/Auth/types"

const JWT_STORAGE__KEY = `${process.env.REACT_APP_COOKIE_PREFIX}__jwt-token`
const getJWTToken = async (): Promise<string | undefined> => {
  return SecureStoragePlugin.get({ key: JWT_STORAGE__KEY })
    .then(res => res.value)
    .catch(() => undefined)
}
const setJWTToken = (value: string): Promise<{ value: boolean }> => SecureStoragePlugin.set({ key: JWT_STORAGE__KEY, value })

const LAST_LOGGED_IN_AS_STORAGE__KEY = `${process.env.REACT_APP_COOKIE_PREFIX}__last-logged-in-as`
const getAccountType = async (): Promise<AccountTypes | undefined> => {
  return SecureStoragePlugin.get({ key: LAST_LOGGED_IN_AS_STORAGE__KEY })
    .then(res => res.value as AccountTypes)
    .catch(() => undefined)
}
const setAccountType = (value: string): Promise<{ value: boolean }> => SecureStoragePlugin.set({ key: LAST_LOGGED_IN_AS_STORAGE__KEY, value })

export const secureStorage = {
  JWT_STORAGE__KEY,
  getJWTToken,
  setJWTToken,

  LAST_LOGGED_IN_AS_STORAGE__KEY,
  getAccountType,
  setAccountType,
}
