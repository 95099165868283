import React, { createContext, useCallback, useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { useQueryClient } from "@tanstack/react-query"
import QueryString from "qs"

import { fetchMeQueryKey, useFetchMeQuery, useUserLoginMutation, useUserLogoutMutation } from "@root/queries/user/userById"
import { GenericStatusErrorType } from "@root/types/errors"
import { secureStorage } from "@utils/storage"
import { useAppConfig } from "../AppConfig"
import { AccountTypes, Authentication } from "./types"

const Context = createContext<Authentication>({
  logIn: () => null,
  logOut: () => null,
  user: undefined,
  setAccountType: () => null,
  accountType: undefined,
})

const AuthProvider: React.FC = ({ children }) => {
  const queryClient = useQueryClient()
  const [accountType, setAccountType] = React.useState<undefined | AccountTypes | null>(undefined)
  const { data: user = undefined } = useFetchMeQuery({})
  const userLoginMutation = useUserLoginMutation()
  const userLogoutMutation = useUserLogoutMutation()
  const history = useHistory()
  const { setIsDemo } = useAppConfig()

  const logIn = useCallback(
    async (data: { email: string; password: string }, loginAs: AccountTypes) => {
      try {
        const isDemo = data.email === "demo@miquikplow.com" && data.password === "QuikplowDemo123!"
        const apiDomain = (isDemo ? process.env.REACT_APP_API_URL_DEMO : process.env.REACT_APP_API_URL) || ""
        setIsDemo(isDemo)

        await userLoginMutation.mutateAsync({ accountType: loginAs, data, apiDomain })
        toast.success("Logged in successfully", { autoClose: 1500 })
        setAccountType(loginAs)

        const query = QueryString.parse(window.location.search, { ignoreQueryPrefix: true })
        if (typeof query.redirect === "string") {
          history.push(query.redirect)
        } else {
          history.push("/home")
        }
      } catch (e) {
        const errors = e as GenericStatusErrorType
        secureStorage.setAccountType("")
        secureStorage.setJWTToken("")
        toast.error(errors.message)
      }
    },
    [history, userLoginMutation, setIsDemo],
  )

  const logOut = useCallback(async () => {
    try {
      await userLogoutMutation.mutateAsync()
      setAccountType(null)
      history.push("/login")
    } catch (e) {
      // silently fail
    }
  }, [history, userLogoutMutation])

  useEffect(() => {
    const onMount = async () => {
      const savedAccountType = await secureStorage.getAccountType()
      setAccountType(savedAccountType)
    }
    onMount()
  }, [queryClient])

  useEffect(() => {
    if (accountType === null) {
      queryClient.invalidateQueries([fetchMeQueryKey])
    }
  }, [queryClient, accountType])

  return (
    <Context.Provider
      value={{
        user,
        logIn,
        logOut,
        setAccountType,
        accountType,
      }}
    >
      {children}
    </Context.Provider>
  )
}

const useAuth = (): Authentication => useContext(Context)

export { AuthProvider, useAuth }
