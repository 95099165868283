import * as React from "react"

import { joinClassNames } from "@utils/joinClassNames"

import classes from "./index.module.scss"

type LoaderProps = {
  size?: "sm" | "md" | "lg" | "xl"
  color?: "light" | "dark" | "primary"
  className?: string
}
export const Loader: React.FC<LoaderProps> = props => {
  const { size = "sm", color = "dark", className } = props

  const classNames = joinClassNames([classes.loader, classes[`size--${size}`], classes[`color--${color}`], className])

  return <div className={classNames} />
}
