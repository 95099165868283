import type { Upload } from "@elilemons/installmint-lib"

import { isResJSON } from "@utils/isResJSON"
import { secureStorage } from "@utils/storage"
import { GenericStatusError } from "./types/errors"

export const get = async (url: string, options: RequestInit = { headers: {} }): Promise<Response> => {
  const token = await secureStorage.getJWTToken()
  let headers = {}

  if (typeof options?.headers === "object" && options.headers !== null) {
    headers = options.headers
  }

  const formattedOptions: RequestInit = {
    ...options,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
      ...headers,
    },
  }

  return fetch(url, formattedOptions)
}

export const post = async (url: string, options: RequestInit = { headers: {} }): Promise<Response> => {
  const token = await secureStorage.getJWTToken()
  let headers = {}

  if (typeof options?.headers === "object" && options.headers !== null) {
    headers = options.headers
  }

  const formattedOptions: RequestInit = {
    ...options,
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
      ...headers,
    },
  }

  return fetch(url, formattedOptions)
}

export const patch = async (url: string, options: RequestInit = { headers: {} }): Promise<Response> => {
  const token = await secureStorage.getJWTToken()
  let headers = {}

  if (typeof options?.headers === "object" && options.headers !== null) {
    headers = options.headers
  }

  const formattedOptions: RequestInit = {
    ...options,
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
      ...headers,
    },
  }

  return fetch(url, formattedOptions)
}

export const del = async (url: string, options: RequestInit = { headers: {} }): Promise<Response> => {
  const token = await secureStorage.getJWTToken()
  let headers = {}

  if (typeof options?.headers === "object" && options.headers !== null) {
    headers = options.headers
  }

  return fetch(url, {
    ...options,
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
      ...headers,
    },
  })
}

export const uploadImage = async (image: File, altText: string, apiDomain: string, isPrivate?: boolean, options?: RequestInit): Promise<Response> => {
  const token = await secureStorage.getJWTToken()
  const formData = new FormData()
  formData.append("file", image)
  formData.append("alt", altText || image.name)

  return fetch(`${apiDomain}/api/upload?isPrivate=${isPrivate ? "1" : "0"}`, {
    ...options,
    method: "post",
    body: formData,
    headers: {
      Authorization: `JWT ${token}`,
    },
  })
}

export const deleteImage = (id: string, apiDomain: string): Promise<Response> => {
  return del(`${apiDomain}/api/upload/${id}`)
}

interface DeleteUploadArgs {
  upload: string | Upload
  apiDomain: string
}
export const deleteUpload = (args: DeleteUploadArgs): Promise<Response> => {
  const { upload, apiDomain } = args
  const idToDelete = typeof upload === "string" ? upload : upload.id
  return del(`${apiDomain}/api/uploads/${idToDelete}`)
}
// -_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-
// V2 Functions
// -_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-
interface UploadFileArgs {
  file: File
  altText: string
  isPrivate?: boolean
  apiDomain: string
  options?: RequestInit
}
type UploadFileType = (Args: UploadFileArgs) => Promise<Upload>
export const uploadFile: UploadFileType = async ({ file, altText, apiDomain, isPrivate, options }) => {
  const token = await secureStorage.getJWTToken()
  const formData = new FormData()
  formData.append("file", file)
  formData.append("alt", altText || file.name)

  const res = await fetch(`${apiDomain}/api/upload?isPrivate=${isPrivate ? "1" : "0"}`, {
    ...options,
    method: "post",
    body: formData,
    headers: {
      Authorization: `JWT ${token}`,
    },
  })

  try {
    if (isResJSON(res) && res.ok) {
      const json = await res.json()
      return json.doc
    }
  } catch (e: unknown) {
    throw GenericStatusError({
      status: res.status,
      message: "An error occurred while uploading the file",
    })
  }

  throw GenericStatusError({
    status: res.status,
    message: "An error occurred while uploading the file",
  })
}
