export const APP_ROUTES = {
  unauthenticated: {
    login: "/login",
    recoverPassword: "/recover-password",
    resetPassword: "/reset-password",
    createAccountPage: "/create-account",
    sellerSignUpPage: "/account-creation", // Buyers sign up via email

    buyerAccountCreation: "/invite/set-password",
    closeThisBrowser: "/close-this-browser",
    termsOfUse: "/terms-of-use",
    privacyPolicy: "/privacy-policy",

    landingPage: "/about",
  },

  authenticated: {
    shared: {
      // General
      home: "/home",
      notFound: "/not-found",
      contactUs: "/contact-us",
      contactSubmission: "/contact-submission-success",

      // Contracts
      contract: "/contract",
      contractWithSlug: "/contract/:contractId",
      contractPayments: "/contract/:contractId/payments",
      contractPaymentWithSlug: "/contract/:contractId/payments/:paymentId",

      // Payments
      payments: "/payments",
      paymentWithSlug: "/payments/:paymentId",

      // Account
      account: "/account",
      accountWithSlug: "/account/:userId",
      resetPassword: "/account/reset-password",
    },

    sellers: {
      // general routes
      createAccountSuccess: "/account-creation/success",

      // contract workflow
      contractEdit: "/contract/edit",
      contractEditWithStep: "/contract/edit/step/:stepId",
      contractEditWithSlug: "/contract/:contractId/edit",
      contractEditWithSlugWithStep: "/contract/:contractId/edit/step/:stepId",
      contractEditWithSlugWithStepWithSellerSubscriptionStatus: "/contract/:contractId/edit/step/:stepId/:sellerSubscriptionStatus",
      contractStatus: "/contract/:contractId/status",
      contractOverview: "/contract/:contractId/overview",
    },

    buyers: {
      // general routes
      home: "/home",

      // contract workflow
      contractReview: "/contract/:contractId/review",
      contractReviewWithStep: "/contract/:contractId/review/step/:stepId",
    },
  },
}
