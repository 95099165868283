import React, { createContext, useContext } from "react"

type ContextType = {
  isDemo: boolean
  setIsDemo: (isDemo: boolean) => void
  apiDomain: string
}
const Context = createContext<ContextType>({
  isDemo: false,
  setIsDemo: () => undefined,
  apiDomain: "",
})

const AppConfigProvider: React.FC = ({ children }) => {
  const [isDemo, setIsDemo] = React.useState(false)
  const apiDomain = (isDemo ? process.env.REACT_APP_API_URL_DEMO : process.env.REACT_APP_API_URL) || ""

  return <Context.Provider value={{ isDemo, setIsDemo, apiDomain }}>{children}</Context.Provider>
}

const useAppConfig = (): ContextType => useContext(Context)

export { AppConfigProvider, useAppConfig }
