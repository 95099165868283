import { Buyer, Doc, DocWithToken, Seller } from "@elilemons/installmint-lib"
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query"
import decodeJWT from "jwt-decode"
import QueryString from "qs"

import { useAppConfig } from "@components/appProviders/AppConfig"
import { AccountTypes, User } from "@components/appProviders/Auth/types"
import { get, patch, post } from "@root/api"
import { GenericStatusError } from "@root/types/errors"
import { canLoop } from "@utils/canLoop"
import { isResJSON } from "@utils/isResJSON"
import { secureStorage } from "@utils/storage"

export const fetchMeQueryKey = "fetch-me"

type useFetchMeQueryProps = {
  accountType?: AccountTypes
  depth?: number
  refetchInterval?: number
}
export function useFetchMeQuery({ accountType, refetchInterval }: useFetchMeQueryProps): UseQueryResult<User | null, unknown> {
  const { setIsDemo } = useAppConfig()

  const queryRes = useQuery({
    queryKey: [fetchMeQueryKey],
    queryFn: async () => {
      const token = await secureStorage.getJWTToken()
      if (token) {
        let apiDomain = process.env.REACT_APP_API_URL
        const { email }: { email: string } = decodeJWT(token)
        if (email === "demo@miquikplow.com") {
          apiDomain = process.env.REACT_APP_API_URL_DEMO
          setIsDemo(true)
        }
        const storageAccountType = await secureStorage.getAccountType()
        const attemptToLoginAs = accountType || storageAccountType
        if (attemptToLoginAs) {
          const res = await get(`${apiDomain}/api/${attemptToLoginAs}/me?depth=0`)
          if (res.status === 200) {
            const json = await res.json()
            return json.user
          }
        }
      }
      return null
    },
    refetchInterval,
    onError: () => {
      throw GenericStatusError({ status: 501, message: "An unknown error has occurred" })
    },
  })

  return queryRes
}

const getUserByIDQueryKey = "get-user-by-id"
type getUserByIDQueryProps = {
  accountType: AccountTypes
  userId?: string
}
export function getUserByIDQuery({ accountType, userId }: getUserByIDQueryProps): UseQueryResult<User | null, unknown> {
  const { apiDomain } = useAppConfig()
  const queryRes = useQuery({
    queryKey: [getUserByIDQueryKey, userId, apiDomain, accountType],
    queryFn: async () => {
      const res = await get(`${apiDomain}/api/${accountType}/${userId}`)

      if (isResJSON(res)) {
        const json = await res.json()
        if (res.status === 200) {
          return json
        }
        if (res.status === 400) {
          if (canLoop(json.errors)) {
            throw GenericStatusError({
              status: 400,
              message: "There was an issue getting this user.",
              data: { ...json },
            })
          }
        }

        throw GenericStatusError({
          status: 501,
          message: "An unknown error occurred when attempting to log you in",
        })
      }

      return null
    },
    onError: () => {
      throw GenericStatusError({ status: 501, message: "An unknown error has occurred" })
    },
    enabled: !!userId,
  })

  return queryRes
}

type MeMutationProps = {
  data: any
  accountType: AccountTypes
  depth?: number
  queryParams?: {
    [key: string]: any
  }
}
export function useMeMutation(): UseMutationResult<Doc<Seller | Buyer>, unknown, MeMutationProps> {
  const queryClient = useQueryClient()
  const { data: user } = useFetchMeQuery({})
  const { apiDomain } = useAppConfig()

  const mutation = useMutation({
    mutationFn: async ({ data, accountType, depth = 0, queryParams = {} }: MeMutationProps) => {
      const params = QueryString.stringify(
        {
          ...queryParams,
          depth: depth || queryParams?.depth || 0,
        },
        { addQueryPrefix: true },
      )

      if (user?.id) {
        const res = await patch(`${apiDomain}/api/${accountType}/${user?.id}${params}`, {
          body: JSON.stringify(data),
        })
        if (isResJSON(res)) {
          const json = await res.json()
          if (res.status === 200) {
            return json
          }
          if (res.status === 400) {
            if (canLoop(json.errors)) {
              throw GenericStatusError({
                status: 400,
                message: "Validation errors occurred during the form submission",
                data: { ...json },
              })
            }
          }
        }

        throw GenericStatusError({
          status: 501,
          message: "An unknown error occurred while updating the user.",
        })
      }

      throw GenericStatusError({
        status: 400,
        message: "Could not find your users id.",
      })
    },
    onSuccess: data => {
      if (data?.doc) {
        queryClient.invalidateQueries([fetchMeQueryKey])
        queryClient.setQueryData([fetchMeQueryKey], data.doc)
      }
    },
  })

  return mutation
}

type MutateUserLoginProps = {
  data: {
    email: string
    password: string
  }
  accountType: AccountTypes
  apiDomain?: string
}
export function useUserLoginMutation(): UseMutationResult<DocWithToken<Seller> | DocWithToken<Buyer>, unknown, MutateUserLoginProps> {
  const queryClient = useQueryClient()
  const { apiDomain: appConfigApiDomain } = useAppConfig()

  const mutation = useMutation({
    mutationFn: async ({ data, accountType, apiDomain }: MutateUserLoginProps) => {
      const res = await post(`${apiDomain || appConfigApiDomain}/api/${accountType}/login?depth=0`, {
        body: JSON.stringify(data),
      })

      if (isResJSON(res)) {
        if (res.status === 200) {
          return res.json()
        }

        if (res.status === 401) {
          throw GenericStatusError({
            status: 401,
            message: "There was a problem logging in with the credentials you provided.",
          })
        }
      }

      throw GenericStatusError({
        status: 501,
        message: "An unknown error occurred when attempting to log you in",
      })
    },
    onSuccess: async (data, { accountType }) => {
      await secureStorage.setJWTToken(data.token)
      await secureStorage.setAccountType(accountType)
      queryClient.invalidateQueries([fetchMeQueryKey])
      queryClient.setQueryData([fetchMeQueryKey], { ...data.user })
    },
  })

  return mutation
}

export function useUserLogoutMutation(): UseMutationResult<null, unknown, void> {
  const queryClient = useQueryClient()
  const { apiDomain } = useAppConfig()

  const mutation = useMutation({
    mutationFn: async () => {
      const accountType = await secureStorage.getAccountType()
      const res = await post(`${apiDomain}/api/${accountType}/logout`)

      if (isResJSON(res)) {
        if (res.status === 200) {
          return res.json()
        }
      }

      throw GenericStatusError({
        status: 501,
        message: "An unknown error occurred when attempting to log you out",
      })
    },
    onMutate: async () => {
      await secureStorage.setJWTToken("")
      queryClient.invalidateQueries([fetchMeQueryKey])
      queryClient.setQueryData([fetchMeQueryKey], null)
    },
  })

  return mutation
}
