import * as React from "react"
import { createContext } from "react"
import { useHistory } from "react-router-dom"

import { Contract } from "@root/../../installmint-lib/dist"
import { APP_ROUTES } from "@root/appRoutes"
import { useAuth } from "../Auth"

const defaultState: Partial<Contract> = {}

type State = Partial<Contract>
type ActionTypes = "UPDATE_CONTRACT" | "RESET_CONTRACT"
type Action = {
  type: ActionTypes
  payload?: {
    [key: string]: unknown
  }
}

type Reducer = (state: State, action: Action) => State
const reducer: Reducer = (state, action) => {
  const { type, payload } = action
  let copyOfState = { ...state }

  switch (type) {
    case "UPDATE_CONTRACT":
      if (payload) {
        copyOfState = {
          ...copyOfState,
          ...payload,
        }
      }
      break

    case "RESET_CONTRACT":
      copyOfState = defaultState
      break

    default:
      break
  }

  return copyOfState
}

const MAX_STEPS = 9

type ContextType = {
  contractProviderState: State
  dispatchContractProviderState: ({ type, payload }: Action) => void
  currentStep: number
  setCurrentStep: (stepIndex: number) => void
  stepBack: () => void
  stepForward: () => void
  maxSteps: number
}
const Context = createContext<ContextType>({
  contractProviderState: {},
  dispatchContractProviderState: () => null,
  currentStep: 0,
  setCurrentStep: () => null,
  stepBack: () => null,
  stepForward: () => null,
  maxSteps: MAX_STEPS,
})

type NewContractProviderProps = {
  maxSteps: number
}

const ContractProvider: React.FC<NewContractProviderProps> = props => {
  const { children, maxSteps } = props
  const { user } = useAuth()
  const history = useHistory()
  const [contractProviderState, dispatchContractProviderState] = React.useReducer(reducer, { ...user })
  const [currentStep, setCurrentStep] = React.useState(0)

  const stepBack = () => {
    setCurrentStep(Math.max(0, currentStep - 1))
  }

  const stepForward = () => {
    setCurrentStep(Math.min(maxSteps, currentStep + 1))
  }

  React.useEffect(() => {
    if (currentStep > maxSteps) {
      history.push(APP_ROUTES.authenticated.shared.home)
    }
  }, [currentStep, maxSteps, history])

  React.useEffect(() => {
    if (currentStep > maxSteps) {
      console.error(`Attempted to increment contract creation step count(${currentStep}) past the max step count(${maxSteps})`)
      history.push(APP_ROUTES.authenticated.shared.home)
    }
  }, [currentStep, maxSteps, history])

  React.useEffect(() => {
    if (user === null) {
      dispatchContractProviderState({
        type: "RESET_CONTRACT",
      })
      setCurrentStep(0)
    } else {
      dispatchContractProviderState({
        type: "UPDATE_CONTRACT",
        payload: { ...user },
      })
    }
  }, [user])
  return (
    <Context.Provider
      value={{
        contractProviderState,
        dispatchContractProviderState,

        maxSteps,
        currentStep,
        setCurrentStep,
        stepBack,
        stepForward,
      }}
    >
      {children}
    </Context.Provider>
  )
}

const useContractProvider = (): ContextType => React.useContext(Context)

export { ContractProvider, useContractProvider }
