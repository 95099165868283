import * as React from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { BrowserRouter as Router } from "react-router-dom"
import PullToRefresh from "react-simple-pull-to-refresh"
import { Slide, toast, ToastContainer } from "react-toastify"
import { GridProvider } from "@faceless-ui/css-grid"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { SWRConfig } from "swr"

import { AppConfigProvider } from "@components/appProviders/AppConfig"
import { AuthProvider } from "@components/appProviders/Auth"
import { ContractProvider } from "@components/appProviders/ContractProvider"
import { DialogProvider } from "@components/appProviders/Dialogs"
import { NavigationProvider } from "@components/appProviders/Navigation"
import { InAppBrowserListener } from "@components/elements/InAppBrowserListener"
import { Loader } from "@components/elements/Loader"
import { OnRouteChange } from "@components/elements/OnRouteChange"
import { Routes } from "./views/Routes"

import "react-toastify/dist/ReactToastify.css"
import "@scss/toastify.scss"

import classes from "./App.module.scss"

export const App: React.FC = () => {
  const handleRefresh = async () => {
    window.location.reload()
    return Promise.resolve()
  }
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: true,
        staleTime: Infinity,
        structuralSharing: false,
      },
    },
  })

  return (
    <React.Fragment>
      <GridProvider
        rowGap={{
          s: "2rem",
          m: "2rem",
          l: "2rem",
          xl: "2rem",
        }}
        colGap={{
          s: "2rem",
          m: "2rem",
          l: "2rem",
          xl: "2rem",
        }}
        cols={{
          s: 4,
          m: 12,
          l: 12,
          xl: 12,
        }}
      >
        <HelmetProvider>
          <AppConfigProvider>
            <SWRConfig>
              <QueryClientProvider client={queryClient}>
                {/* The rest of your application */}
                <ReactQueryDevtools initialIsOpen={false} />
                <div className="App">
                  <Helmet>
                    <meta charSet="utf-8" name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
                  </Helmet>

                  <Router>
                    <NavigationProvider>
                      <DialogProvider>
                        <OnRouteChange />
                        <InAppBrowserListener />

                        <AuthProvider>
                          <ContractProvider maxSteps={9}>
                            <PullToRefresh
                              onRefresh={handleRefresh}
                              refreshingContent={
                                <div className={classes.pullToRefreshContainer}>
                                  <Loader size="sm" color="primary" />
                                </div>
                              }
                              pullingContent={
                                <div className={classes.pullToRefreshContainer}>
                                  <Loader size="sm" color="primary" />
                                </div>
                              }
                            >
                              <Routes />
                            </PullToRefresh>
                          </ContractProvider>
                        </AuthProvider>
                      </DialogProvider>
                    </NavigationProvider>
                  </Router>
                </div>
              </QueryClientProvider>
            </SWRConfig>
          </AppConfigProvider>
        </HelmetProvider>
      </GridProvider>
      <ToastContainer position={toast.POSITION.BOTTOM_CENTER} transition={Slide} autoClose={2000} />
    </React.Fragment>
  )
}
