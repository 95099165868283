import * as React from "react"
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom"

import { useAuth } from "@components/appProviders/Auth"
import { FullscreenLoader } from "@components/elements/FullscreenLoader"
import { APP_ROUTES } from "@root/appRoutes"

// // // // // // // // //
// Unauthenticated Pages
// // // // // // // // //
const Login = React.lazy(() => import("./Unauthenticated/Login"))
const SellerSignUpForm = React.lazy(() => import("./Unauthenticated/SignUpForm"))
const RecoverPassword = React.lazy(() => import("./Unauthenticated/RecoverPassword"))
const ResetPassword = React.lazy(() => import("./Unauthenticated/ResetPassword"))
const CloseThisBrowser = React.lazy(() => import("./Unauthenticated/CloseThisBrowser"))
const TermsOfUse = React.lazy(() => import("./Unauthenticated/TermsOfUse"))
const PrivacyPolicy = React.lazy(() => import("./Unauthenticated/PrivacyPolicy"))
const LandingPage = React.lazy(() => import("./Unauthenticated/LandingPage"))
// // // // // // // // //
// Seller Pages
// // // // // // // // //
const CreateSellerAccountSuccess = React.lazy(() => import("./Authenticated/Seller/Onboarding/CreateAccountSuccess"))
const ContractEdit = React.lazy(() => import("./Authenticated/Seller/ContractEdit"))

const ContractStatus = React.lazy(() => import("./Authenticated/Seller/ContractStatus"))

// // // // // // // // //
// Buyer Pages
// // // // // // // // //
const ContractReview = React.lazy(() => import("./Authenticated/Buyer/ContractReview"))

// // // // // // // // //
// Shared Auth Pages
// // // // // // // // //
const Home = React.lazy(() => import("./Authenticated/Shared/Home"))
const NotFound = React.lazy(() => import("./Authenticated/Shared/NotFound"))
const Contact = React.lazy(() => import("./Authenticated/Shared/Contact"))
const ContactSubmission = React.lazy(() => import("./Authenticated/Shared/ContactSubmissionSuccess"))
const Payments = React.lazy(() => import("./Authenticated/Shared/Payments"))
const PaymentDetails = React.lazy(() => import("./Authenticated/Shared/PaymentDetails"))
const Account = React.lazy(() => import("./Authenticated/Shared/Account"))
const EditAccount = React.lazy(() => import("./Authenticated/Shared/Account/AccountForm"))
const AuthenticatedResetPassword = React.lazy(() => import("./Authenticated/Shared/Account/ResetPassword"))
const ContractHome = React.lazy(() => import("./Authenticated/Shared/ContractHome"))

// // // // // // // // //
// Unauthenticated Routes
// // // // // // // // //
const unauthenticatedRoutes = [
  {
    exact: true,
    path: APP_ROUTES.unauthenticated.login,
    component: Login,
  },
  {
    exact: true,
    path: APP_ROUTES.unauthenticated.sellerSignUpPage,
    component: SellerSignUpForm,
  },
  {
    exact: true,
    path: APP_ROUTES.unauthenticated.recoverPassword,
    component: RecoverPassword,
  },
  {
    exact: true,
    path: APP_ROUTES.unauthenticated.resetPassword,
    component: ResetPassword,
  },
  {
    exact: true,
    path: APP_ROUTES.unauthenticated.buyerAccountCreation,
    component: ResetPassword,
  },
  {
    exact: true,
    path: APP_ROUTES.unauthenticated.closeThisBrowser,
    component: CloseThisBrowser,
  },
  {
    exact: true,
    path: APP_ROUTES.unauthenticated.termsOfUse,
    component: TermsOfUse,
  },
  {
    exact: true,
    path: APP_ROUTES.unauthenticated.privacyPolicy,
    component: PrivacyPolicy,
  },
  {
    exact: true,
    path: APP_ROUTES.unauthenticated.landingPage,
    component: LandingPage,
  },
]

// // // // // // // // //
// Authenticated Routes
// // // // // // // // //
const sellerRoutes = [
  // Contract Workflow
  {
    exact: true,
    path: APP_ROUTES.authenticated.sellers.contractEdit,
    component: ContractEdit,
  },

  {
    exact: true,
    path: APP_ROUTES.authenticated.sellers.contractStatus,
    component: ContractStatus,
  },
  {
    exact: true,
    path: APP_ROUTES.authenticated.sellers.contractEdit,
    component: ContractEdit,
  },
  {
    exact: true,
    path: APP_ROUTES.authenticated.sellers.contractEditWithStep,
    component: ContractEdit,
  },
  {
    exact: true,
    path: APP_ROUTES.authenticated.sellers.contractEditWithSlug,
    component: ContractEdit,
  },
  {
    exact: true,
    path: APP_ROUTES.authenticated.sellers.contractEditWithSlugWithStep,
    component: ContractEdit,
  },
  {
    exact: true,
    path: APP_ROUTES.authenticated.sellers.contractEditWithSlugWithStepWithSellerSubscriptionStatus,
    component: ContractEdit,
  },
  {
    exact: true,
    path: APP_ROUTES.authenticated.sellers.createAccountSuccess,
    component: CreateSellerAccountSuccess,
  },
]

const buyerRoutes = [
  {
    exact: true,
    path: APP_ROUTES.authenticated.buyers.contractReview,
    component: ContractReview,
  },
  {
    exact: true,
    path: APP_ROUTES.authenticated.buyers.contractReviewWithStep,
    component: ContractReview,
  },
]

const sharedAuthRoutes = [
  {
    exact: true,
    path: APP_ROUTES.unauthenticated.closeThisBrowser,
    component: CloseThisBrowser,
  },
  {
    exact: true,
    path: APP_ROUTES.unauthenticated.termsOfUse,
    component: TermsOfUse,
  },
  {
    exact: true,
    path: APP_ROUTES.unauthenticated.privacyPolicy,
    component: PrivacyPolicy,
  },
  {
    exact: true,
    path: APP_ROUTES.authenticated.shared.home,
    component: Home,
  },
  {
    exact: true,
    path: APP_ROUTES.authenticated.shared.notFound,
    component: NotFound,
  },
  {
    exact: true,
    path: APP_ROUTES.authenticated.shared.contract,
    component: ContractHome,
  },
  {
    exact: true,
    path: APP_ROUTES.authenticated.shared.contractWithSlug,
    component: ContractHome,
  },

  {
    exact: true,
    path: APP_ROUTES.authenticated.shared.contractPayments,
    component: Payments,
  },
  {
    exact: true,
    path: APP_ROUTES.authenticated.shared.contractPaymentWithSlug,
    component: PaymentDetails,
  },
  {
    exact: true,
    path: APP_ROUTES.authenticated.shared.payments,
    component: Payments,
  },
  {
    exact: true,
    path: APP_ROUTES.authenticated.shared.paymentWithSlug,
    component: PaymentDetails,
  },
  {
    exact: true,
    path: APP_ROUTES.authenticated.shared.account,
    component: Account,
  },
  {
    exact: true,
    path: APP_ROUTES.authenticated.shared.accountWithSlug,
    component: EditAccount,
  },
  {
    exact: true,
    path: APP_ROUTES.authenticated.shared.resetPassword,
    component: AuthenticatedResetPassword,
  },

  {
    exact: true,
    path: APP_ROUTES.authenticated.shared.contactUs,
    component: Contact,
  },
  {
    exact: true,
    path: APP_ROUTES.authenticated.shared.contactSubmission,
    component: ContactSubmission,
  },

  {
    exact: true,
    path: APP_ROUTES.unauthenticated.termsOfUse,
    component: TermsOfUse,
  },
  {
    exact: true,
    path: APP_ROUTES.unauthenticated.landingPage,
    component: LandingPage,
  },
]

export const Routes: React.FC = () => {
  const { accountType, user } = useAuth()
  const history = useHistory()
  const location = useLocation()

  const attemptedUserFetch = user !== undefined
  const isAuthenticated = user && (accountType === "sellers" || accountType === "buyers")

  React.useEffect(() => {
    if (location?.pathname.startsWith("/uni/")) {
      history.push(`${location.pathname.replace("/uni/", "/")}${location.search}${location.hash}`)
    }
  }, [location, history])

  return (
    <React.Suspense fallback={<FullscreenLoader />}>
      {attemptedUserFetch && !isAuthenticated && (
        <Switch>
          {unauthenticatedRoutes.map(route => (
            <Route key={route.path} {...route} />
          ))}

          {location && ["/terms-of-use", "/tos", "/terms-of-service"].includes(location.pathname) ? (
            <Route>
              <Redirect exact from="/terms-of-service" to="/terms-of-use" />
              <Redirect exact from="/tos" to="/terms-of-use" />
            </Route>
          ) : (
            <Route>
              <Redirect to={APP_ROUTES.unauthenticated.login} />
            </Route>
          )}
        </Switch>
      )}

      {attemptedUserFetch && isAuthenticated && (
        <Switch>
          {accountType === "sellers" && sellerRoutes.map(route => <Route key={route.path} {...route} />)}
          {accountType === "buyers" && buyerRoutes.map(route => <Route key={route.path} {...route} />)}

          {sharedAuthRoutes.map(route => (
            <Route key={route.path} {...route} />
          ))}

          {location && ["/terms-of-use", "/tos", "/terms-of-service"].includes(location.pathname) ? (
            <Route>
              <Redirect exact from="/terms-of-service" to="/terms-of-use" />
              <Redirect exact from="/tos" to="/terms-of-use" />
            </Route>
          ) : (
            <Route>
              <Redirect exact from="/" to="/home" />
            </Route>
          )}

          <Route>
            <Redirect to={APP_ROUTES.authenticated.shared.notFound} />
          </Route>
        </Switch>
      )}
    </React.Suspense>
  )
}
