import React from "react"

import { Loader } from "../Loader"

import classes from "./index.module.scss"

export const FullscreenLoader: React.FC = () => {
  return (
    <div className={classes.fullscreenLoader}>
      <Loader size="xl" color="primary" />
    </div>
  )
}
